<template>
  <div
    style="position: relative; overflow: hidden; height: 100%; display: flex; flex-direction: column">
    <iframe id="download" style="display: none"></iframe>
    <div>
      <input ref="inputFile" type="file" style="display:none" multiple
        @change="inputFileImagemChange">
      <div style="display: flex; align-items: center">
        <button type="button" @click="$refs.inputFile.click()">+ Adicionar
          imagens</button>
        <div style="flex: 1; text-align: center">
          <progress v-show="uploading" ref="progress" min="0" max="100"
            value="0">0</progress>
        </div>
        <div v-if="selecionar"
          style="text-align: right; display: flex; align-items: center">
          <span style="display: inline-block; margin-right: 10px">
            <span
              style="display: inline-block; margin-right: 5px; font-weight: bold; cursor: pointer"
              @click="selecionados = []">X</span>
            {{ selecionados.length }}
            {{ selecionados.length > 1 ? 'itens selecionados' : 'item selecionado' }}
          </span>
          <button type="button" @click="remover"><i
              class="mdi mdi-trash-can-outline mdi-18px"></i></button>
          <button type="button" @click="download"><i
              class="mdi mdi-download mdi-18px"></i> </button>
          <button type="button" @click="todos"><i
              class="mdi mdi-check-all mdi-18px"></i> </button>
        </div>
        <div v-else style="text-align: right">
          <button type="button"
            @click="selecionar = !selecionar">{{ selecionar ? 'Cancelar' : 'Selecionar' }}</button>
        </div>
      </div>
    </div>
    <div class="s-galeria" style="overflow: auto">
      <div v-for="(i, idx) in imagensUrl" @click="selected = i" draggable="true"
        @dragstart="dragStart(idx)" @dragover.prevent="dragOver(idx)"
        @drop="drop(idx)" :class="[
          selecionados.find(e => e === i) ? 's-galeria-div-selecionada' : '',
          hoverIndex === idx ? 's-galeria-div-hover' : ''
        ]" :key="idx">
        <span v-show="selecionar" class="mdi mdi-check-bold mdi-18px"
          :style="selecionados.find(e => e === i) ? {background: 'blue'} : {color: 'grey'}"
          @click.stop="toggleSelecionado(i)">
        </span>
        <img :src="getImageUrl(i, 'imagemMin')"
          :class="selecionados.find(e => e === i) ? 's-galeria-img-selecionada' : ''">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "s-galeria",

  props: {
    home: String,
    imagens: Array,
  },

  data() {
    return {
      draggingIndex: null,
      hoverIndex: null,
      uploading: false,
      selecionar: false,
      selected: null,
      selecionados: [],
      tmpBasePath: `${this.$siggma.basePath}/data-files/tmp`,
      galeriaBasePath:
        `${this.$siggma.basePath}/data-files/${this.$siggma.empresa}/galeria/${
          this.home || ""
        }`,
      itens: this.imagens || [
        /* {type: 'png', imagem: '1231231', imagemMin: '1231231-min'} */
      ],
    };
  },

  computed: {
    imagensUrl() {
      return this.itens.filter((i) => !i.excluir);
    },
  },

  watch: {
    imagens() {
      this.itens = this.imagens;
    },
    selected(v) {
      v ? this.visualizar(v) : this.dropVisualizar();
    },
    selecionados(v) {
      !v.length && (this.selecionar = false);
    },
  },

  methods: {
    getImageUrl(img, type) {
      if (img.novo) {
        return `${this.tmpBasePath}/${img[type]}`;
      }
      return img[type].match(/^http/)
        ? img[type]
        : `${this.galeriaBasePath}/${img[type]}.${img.type}`;
    },
    inputFileImagemChange(evt) {
      this.upload(evt.target.files, this.$refs.progress).then((itens) => {
        itens.forEach((i) => {
          i.novo = true;
          this.itens.push(i);
        });
        this.$emit("change", this.itens);
      });
    },
    upload(file, progressBar) {
      const setProgress = (value) => {
        progressBar.value = progressBar.innerHTML = value;
      };

      return new Promise((resolve) => {
        let i = 0;
        const formData = new FormData();
        for (const f of file) {
          formData.append(`file[${i++}]`, f);
        }

        setProgress(0);
        this.uploading = true;

        const xhr = new XMLHttpRequest();
        xhr.upload.addEventListener("progress", function (e) {
          e.lengthComputable && setProgress(e.loaded / e.total * 100 | 0);
        });
        xhr.addEventListener("loadend", () => {
          this.uploading = false;
          this.$refs.inputFile.value = "";
        });
        xhr.addEventListener("error", () => {
          setProgress(0);
          Yanda.message({
            type: "warning",
            msg:
              "Houve um erro ao fazer upload dos arquivos<br><br>Verifique a sua conexão de internet",
          });
        });
        xhr.addEventListener("load", () => {
          if (xhr.status === 200) {
            const resp = JSON.parse(xhr.responseText);
            Notify[resp.type](resp.msg);
            if (resp.type !== "success") {
              setProgress(0);
              return;
            }
            setProgress(100);
            resolve(resp.data);
          }
        });
        xhr.open(
          "POST",
          `${this.$siggma.basePath}/app/files/upload-imagem`,
          true,
        );
        xhr.send(formData);
      });
    },
    toggleSelecionado(i) {
      const tem = this.selecionados.find((e) => e === i);
      if (!tem) {
        this.selecionados.push(i);
        return;
      }
      this.selecionados = this.selecionados.filter((e) => e !== i);
    },
    remover() {
      if (!this.selecionados.length) {
        Notify.warning("Nenhuma imagem foi selecionada");
        return;
      }

      const itens = [];
      this.itens.forEach((i) => {
        const del = this.selecionados.includes(i);
        if (del && i.novo) {
          return;
        }
        del && (i.excluir = true);
        itens.push(i);
      });
      this.itens = itens;
      this.selecionados = [];
      this.$emit("change", this.itens);
    },
    todos() {
      if (this.selecionados.length === this.itens.length) {
        this.selecionados = [];
        return;
      }
      this.selecionados = this.itens.map((i) => i);
    },
    download() {
      if (!this.selecionados.length) {
        Notify.warning("Nenhuma imagem foi selecionada");
        return;
      }

      fetch(
        `${this.$siggma.basePath}/app/files/download-galeria?home=${
          this.home || ""
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
          },
          body: `galeria=${
            encodeURIComponent(JSON.stringify(this.selecionados))
          }`,
        },
      ).then((r) => r.json()).then((r) => {
        if (r.type !== "success") {
          Notify[r.type](r.msg);
          return;
        }
        this.$el.querySelector("#download").src =
          `${this.$siggma.basePath}/data-files/tmp/${r.data}?contentDisposition=attachment&filename=imagens.zip`;
      });
    },
    visualizar(i) {
      globalThis.Siggma.utils.visualizarImagem.open(
        this.getImageUrl(i, "imagem"),
      );
    },
    dropVisualizar() {
      globalThis.Siggma.utils.visualizarImagem.close();
    },
    dragStart(index) {
      this.draggingIndex = index;
      this.hoverIndex = null;
    },
    dragOver(index) {
      this.hoverIndex = index;
    },
    drop(index) {
      if (this.draggingIndex === null || this.draggingIndex === index) {
        return;
      }

      const movedItem = this.itens.splice(this.draggingIndex, 1)[0];
      this.itens.splice(index, 0, movedItem);

      this.draggingIndex = null;
      this.hoverIndex = null;
      this.$emit("change", this.itens);
    },
  },

  destroyed() {
    this.dropVisualizar();
  },
};
</script>

<style>
.s-galeria {
  display: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

.s-galeria::after {
  content: '';
  flex-grow: 999999999;
}

.s-galeria>div {
  flex-grow: 1;
  margin: 6px;
  height: 130px;
  position: relative;
  text-align: center;
}

.s-galeria-div-selecionada {
  border: 1px solid;
}

.s-galeria-div-hover {
  border: 2px dashed blue !important;
  background: rgba(0, 0, 255, 0.1);
}

.s-galeria>div>img {
  height: 130px;
  object-fit: cover;
  max-width: 100%;
  min-width: 100%;
  vertical-align: bottom;
  cursor: pointer;
  transition: all .5s;
}

.s-galeria-img-selecionada {
  max-width: 90% !important;
  min-width: 90% !important;
}

.s-galeria>div>span {
  top: -6px;
  left: -6px;
  color: #fff;
  padding: 3px 5px;
  position: absolute;
  font-size: 10px;
  background: grey;
  text-align: center;
  font-weight: bolder;
  border-radius: 50%;
  cursor: pointer;
}
</style>