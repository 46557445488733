<template>
  <div class="s-petshop-animal" :style="limitHeight ? {height: '100%'} : ''">
    <span v-if="showLimpar && !disabled" id="animalLimpar"
      class="mdi mdi-close-thick" @click="limpar"></span>
    <div ref="animal" style="margin-top: 2px;"></div>
    <div id="titleAnimalInfo">
      <label>{{ animal ? animal.nome : 'Animal' }}</label>
      <div v-if="showFichaIcon"
        style="position: absolute; top: 8px; right: 10px;cursor:pointer"
        @click="openHistorico()" title="Abrir Ficha/Atendimento">
        <i class="mdi mdi-paw mdi-24px"></i>
      </div>
    </div>
    <div id="animalInfo" :style="{
            backgroundColor: bg,
            borderBottom: $slots.footer ? 0 : ''
        }">
      <div v-if="animal"
        style="text-align: center;line-height: 20px;font-size: 12px;">
        <img :src="animal.imagemMin" @click="editarAnimal(animal.id)"
          style="height: 100px;cursor: pointer;border-radius: 4px"
          title="Abrir cadastro" />
        <div style="font-weight: bold" :title="`Animal: ${animal.nome}`">
          <span v-html="animal.inadiplente"></span>
          {{animal.nome}}
        </div>
        <div :title="`Espécie: ${animal.especie} / Raça: ${animal.raca}`">
          {{animal.especie}} / {{animal.raca}}
        </div>
        <div :title="`Porte: ${animal.porte} / Sexo: ${animal.sexo}`">
          {{animal.porte}} / {{animal.sexo}}
        </div>
        <div :title="`Peso: ${animal.peso}`">
          Peso: {{animal.peso}} kg
          <input type="hidden" id="petshopAnimalPeso" v-model="animal.peso">
        </div>
        <div
          :title="`Nasceu em ${animal.dataNascimento} / Status: ${animal.status}`">
          {{ animal.idade }} {{ animal.idade ? '/' : '' }}
          <label
            style="font-weight: bold; text-transform: uppercase; color: black;">
            {{animal.status}}
          </label>
        </div>
        <div v-if="animal.cliente" style="font-weight: bold; cursor:pointer;"
          @click="editarCliente(animal.cliente.cliCod)"
          :title="`Cliente: ${animal.cliente.cliCod} - ${animal.cliente.pessoa.nome}`">
          <s-icon-aviso
            v-if="(animal.cliente.historicosPendentes?.atrasados || animal.cliente.historicosPendentes?.futuros)"
            :style="animal.cliente.historicosPendentes?.atrasados ? 'color: red' : 'color: dimgray'"
            @click="pendencias(animal.cliente.cliCod)">
          </s-icon-aviso>
          <img v-if="!!animal.drsnoopy"
            :src="`${getBasePath()}/img/modulos/petShop.png`"
            style="height:13px;" title="Utiliza aplicativo ZettaPet">
          {{animal.cliente.cliCod}} - {{animal.cliente.pessoa.nome}}
        </div>
        <div v-if="animal.cliente"
          :title="`Telefone: ${animal.cliente.pessoa.telefone}`">
          {{animal.cliente.pessoa.telefone}}
        </div>
        <div style="text-decoration: underline; cursor: pointer;"
          v-if="animal.cliente"
          :title="`Celular: ${animal.cliente.pessoa.celular}`"
          @click.prevent.stop="toWhatsapp(animal.cliente.pessoa.celular)">
          {{animal.cliente.pessoa.celular}}
          <i class="mdi mdi-whatsapp mdi-18px"></i>
        </div>
        <div v-if="animal.cliente"
          :title="`Email: ${animal.cliente.pessoa.email}`">
          {{animal.cliente.pessoa.email}}
        </div>
      </div>
    </div>
    <div>
      <div class="s-petshop-animal-footer" :style="`background-color:${bg}`"
        v-if="$slots.footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import idade from "@/utils/idadeAnimal";

export default {
  name: "s-petshop-animal",

  inject: ["hdl"],

  props: {
    value: [Object, Number, String],
    disabled: Boolean,
    limitHeight: {
      type: Boolean,
      default: false,
    },
    showFichaIcon: {
      type: Boolean,
      default: true,
    },
    defaultWhatsapp: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    showLimpar: false,
    bg: "white",
  }),

  computed: {
    animal() {
      // Quando for passado somente o id do animal
      // Retorna um object simples para ocorrer warnings/erros na renderização
      if (typeof (this.value) === "number") {
        return {
          cliente: {
            pessoa: {},
          },
        };
      }
      return this.value;
    },
  },

  watch: {
    value(novo) {
      this.$refs.animal.value = novo;
      this.showLimpar = !!novo;
    },
    disabled(val) {
      jQuery(this.$refs.animal).foreigninput("disabled", val);
    },
  },

  methods: {
    getBasePath() {
      return globalThis.Siggma.basePath;
    },
    setAnimalInfo(animal) {
      this.bg = "white";
      if (!animal) {
        this.$emit("input", null);
        return;
      }

      if (animal.imagem === undefined) {
        jQuery(this.$refs.animal).foreigninput("change");
        return;
      }

      const status = animal?.cliente?.status || {};
      if (status.inativar || status.cliestBlock) {
        this.bg = "lightcoral";
        Notify.warning("Cliente <b>inativo/bloqueado</b>");
      }

      animal.imagemMin = !animal.imagem?.imagemMin
        ? `${this.getBasePath()}/img/sem_foto_bb.jpg`
        : `${this.getBasePath()}/data-files/${globalThis.Siggma.empresa}${animal.imagem.imagemMin}`;

      animal.idade = idade(animal.dataNascimento);
      animal.peso = animal.peso || "0.0";
      (animal?.cliente?.saldo < 0) &&
        (animal.inadiplente = '<i class="mdi mdi-money"></i> &nbsp;');

      this.$emit("input", animal);
    },

    editarAnimal(id) {
      globalThis.Yanda.newApl(69, id.toString(), this.hdl, (hdl) => {
        hdl.onSave = () => jQuery(this.$refs.animal).foreigninput("change");
      });
    },

    editarCliente(cliCod) {
      globalThis.Yanda.newApl(46, cliCod.toString(), this.hdl);
    },

    pendencias(cliente) {
      globalThis.Yanda.newApl(187, cliente, this.hdl);
    },

    openHistorico() {
      const idTela = globalThis.Siggma.isSnoopySmart ? 274 : 212;
      this.animal
        ? globalThis.Yanda.newApl(idTela, { animal: this.animal.id }, this.hdl)
        : jQuery(this.$refs.animal).focus();
    },

    limpar() {
      this.$emit("input", null);
      this.$nextTick(() => this.hdl?.gridReload?.());
    },

    toWhatsapp(num) {
      if (this.defaultWhatsapp) {
        globalThis.Siggma.sendWhatsapp({
          num,
          pessoa: this.animal.cliente.pessoa.id,
          tipoMensagem: "Petshop atendimentos",
        });
        return;
      }
      this.$emit("whatsapp", num);
    },
  },

  mounted() {
    const $animal = jQuery(this.$refs.animal);
    $animal.foreigninput({
      url: `${this.getBasePath()}/pet-shop/animais/get-by-id`,
      id: 68,
      hdl: this.hdl,
      change: this.setAnimalInfo,
      number: true,
      fieldCod: "id",
      autocomplete: {
        url: `${this.getBasePath()}/pet-shop/animais/find`,
        info: "Pesquise pelo Animal ou pelo Responsável",
        newId: 69,
        onNew: (hdl, value) => (hdl.animal.value.nome = value),
        renderItem: (ul, data) => {
          const obito = data.status === "óbito";
          return jQuery('<li style="display:flex">').append(
            `<div style="flex: 1 auto; overflow: hidden; white-space: nowrap;
              text-overflow: ellipsis; line-height: 25px; border-radius: 4px;
              width: 0; ${obito ? "color: #777;" : ""}">
                <b>${data.id} - ${data.nome.toUpperCase()}</b>
                &nbsp;&nbsp;(${data.cliCod} - ${data.cliNome})
                ${obito ? " (óbito)" : ""}
            </div>`,
          ).appendTo(ul);
        },
      },
    }).find("input").eq(1).css({
      width: "240px",
      paddingRight: "22px",
      textAlign: "left",
    });
    this.value && $animal.val(this.value);
    this.$props.disabled && $animal.foreigninput("disabled", true);
  },
};
</script>

<style>
.s-petshop-animal {
  height: calc(100% - 10px);
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  min-height: 0;
  box-sizing: border-box;
}

.s-petshop-animal #animalLimpar {
  top: 5px;
  left: 210px;
  color: #052F57;
  cursor: pointer;
  position: absolute;
  font-size: 1.2em !important;
  z-index: 1;
}

.s-petshop-animal #titleAnimalInfo {
  background-color: var(--s-box-primary-bg-color);
  color: var(--s-box-primary-color);
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  z-index: 0;
  padding: 0 10px;
  position: inherit;
  font-weight: bold;
  border-radius: 4px 4px 0 0;
  text-transform: uppercase;
  box-sizing: border-box;
}

.s-petshop-animal #titleAnimalInfo label {
  color: #FFF;
  margin-top: 14px;
}

.s-petshop-animal #animalInfo {
  flex: 1;
  border: 1px solid #AAA;
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 1px 0px 4px #c2c2c2;
  border-radius: 0 0 4px 4px;
  background-color: #FFF;
}

.s-petshop-animal-footer {
  border: 1px solid #AAA;
  border-top: 0;
  box-shadow: 1px 0px 4px #c2c2c2;
  border-radius: 0 0 4px 4px;
  background-color: #FFF;
}
</style>