<template>
  <div class="radio-button-large">
    <label v-for="(name, i) in param.radioObject" :key="i"
      class="first-color-radio" :style="`width: ${param.width}px`">
      <input type="radio" :value="i" v-model="input">
      <span :style="`width: ${spanWidth}px`">{{name}}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "s-radio",

  props: {
    param: Object,
  },

  data() {
    return {
      input: "",
      spanWidth: 0,
    };
  },

  watch: {
    input(value) {
      this.$emit("changeradio", value);
    },
  },

  methods: {
    set(value) {
      this.input = value;
    },
  },

  mounted() {
    this.spanWidth = parseInt(this.param.width) + 2;
    this.set(this.param.tipo);
  },
};
</script>

<style>
.radio-button-large {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.radio-button-large label {
  float: left;
  background-color: #EFEFEF;
  border-radius: 4px;
  border: 1px solid #D0D0D0;
}

.radio-button-large label:hover {
  cursor: pointer;
}

.radio-button-large label span {
  text-align: center;
  font-size: 14px;
  padding: 4px 0px;
  display: block;
}

.radio-button-large label input {
  position: absolute;
  display: none;
}

.radio-button-large input:checked+span {
  background-color: #7dcaf0;
  border: 1px solid #67a6c5;
  color: #000;
}

.radio-button-large .first-color-radio {
  background-color: #fff;
  color: #000;
  margin: .5px;
}
</style>